<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
    <div class="container-fluid">

      <div class="fs-4" style="color: #4556be;">Employee Performance</div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="token">
            <router-link to="/main" class="nav-link ">Home</router-link>

          </li>
          <li class="nav-item" v-if="usertype == 'admin'">
            <router-link to="/EmployeeView" class="nav-link ">Employee</router-link>
          </li>
          <li class="nav-item" v-if="usertype == 'admin'">
            <router-link to="/TaskView" class="nav-link ">Task</router-link>
          </li>
          <li class="nav-item" v-if="usertype == 'employee'">
            <router-link to="/EmployeeTaskView" class="nav-link ">Task</router-link>
          </li>
          
          
        </ul>
        
        <div class="btn btn-primary" @click="logout" v-if="token">Logout</div>

        <router-link to="/" class="btn btn-primary" v-else>Login</router-link>
      </div>
    </div>
  </nav>

  <router-view />
</template>

<script>



import { ref } from "vue";
import { jwtDecode } from "jwt-decode";

export default {



  setup() {
    let token = localStorage.getItem("user");
    console.log(token);
    if (token) {
      var decoded = jwtDecode(token);
      console.log(decoded);

      var usertype = decoded.user_type;
      console.log(usertype);
      var usename = decoded.user_nam;
      console.log(usename);
    }

    const searchtitle = ref("")

    const logout = function () {
      localStorage.removeItem('user');
      location.href = "/";
    }

    return {
      searchtitle,
      logout, token, usertype,usename
    }

  }


}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.card {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url(./assets/4K_global-business-structure-networking-analysis-260nw-2151657341.png) no-repeat center 0px;
  background-size: 100%;



}

.navbar-brand {
  color: #465acc;
}

img {
  position: absolute;
  background-position: center, center;
}
</style>
