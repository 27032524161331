import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import router from './router'
import VueApexCharts from "vue3-apexcharts";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
//import CanvasJSChart from '@canvasjs/vue-charts';




//import router from './router'

createApp(App).use(router).use(VueApexCharts).use(VCalendar).mount('#app')
