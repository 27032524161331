<template>
    
    <head>
        <title>A Meaningful Page Title</title>

    </head>
    
    
    <div class="position-absolute top-50 start-50 translate-middle">
      <div class="card" style="width: 50rem;">
        <div class="card-body">
          <form @submit.prevent="login()" align="left">
            <div class="text-start">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" v-model="credential.email" aria-describedby="emailHelp">
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" v-model="credential.password">
              </div>
             
             <button type="submit" class="btn btn-primary" >Submit</button>

              &nbsp;<a href="/CreateAccount" class="text-decoration-none">Create new account</a>

            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import { ref } from 'vue';

  
  import { ref } from "vue";
//import jwt_decode from "jwt-decode";

export default {
    name: 'LoginView',
    setup() {
        const credential = ref({});

        const login = async function () {

            var response = await fetch("/api/login", {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(credential.value)
            });

            if (response.ok) {
                var data = await response.json()
                localStorage.setItem("user", data.token);


                //var decoded = jwt_decode(data.token);
                //alert(JSON.stringify(decoded))

                //alert(JSON.stringify(data))
                alert("login Successfully.")
                location.assign("/main");
                

            } else {
                alert("Incorrect Email or Password ")
            }
        }

        return {
            credential, login,
        }
    }
}
  
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  .card {
    position: relative;
  }
  body{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: url(../assets/4K_global-business-structure-networking-analysis-260nw-2151657341.png) no-repeat center 0px;
    background-size:100%;
  
    
    
  }
  </style>
  