import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../view/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      public: true,
    },
  },

  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../view/mainView.vue')
  },

  {
    path: '/CreateAccount',
    name: 'CreateAccount',
    component: () => import(/* webpackChunkName: "about" */ '../view/CreateAC.vue')
  },

  {
    path: '/EmployeeView',
    name: 'EmployeeView',
    component: () => import(/* webpackChunkName: "about" */ '../view/EmployeeView.vue')
  },

  {
    path: '/Employee/CreateEmployee',
    name: 'CreateEmployee',
    component: () => import(/* webpackChunkName: "about" */ '../view/CreateEmployee.vue')
  },

  {
    path: '/Employee/detail/:id/',
    name: 'EmployeeDetail',
    component: () => import(/* webpackChunkName: "about" */ '../view/EmployeeDetail.vue')
  },

  {
    path: '/testview',
    name: 'TestView',
    component: () => import(/* webpackChunkName: "about" */ '../view/TestView.vue')
  },

  {
    path: '/TaskView',
    name: 'TaskView',
    component: () => import(/* webpackChunkName: "about" */ '../view/TaskView.vue')
  },

  {
    path: '/Task/CreateTask',
    name: 'CreateTask',
    component: () => import(/* webpackChunkName: "about" */ '../view/CreateTask.vue')
  },

  {
    path: '/Task/detail/:id/',
    name: 'TaskDetail',
    component: () => import(/* webpackChunkName: "about" */ '../view/TaskDetail.vue')
  },

  {
    path: '/EmployeeTaskView',
    name: 'EmployeeTaskView',
    component: () => import(/* webpackChunkName: "about" */ '../view/EmployeeTaskView.vue')
  },

  {
    path: '/Employee/Task/detail/:id/',
    name: 'EmployeeTaskDetail',
    component: () => import(/* webpackChunkName: "about" */ '../view/EmployeeTaskDetail.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});
export default router
